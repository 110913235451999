import React, { useEffect, useState, useCallback } from "react";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import dot from "dot-object";
import TextMaskCustom from "./input-text-mask";
import hooks from "./hooks";
import debounce from "lodash.debounce";
import { getData, questionnaireVersion1 } from "./util";

function View({ question, context }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || "",
  });

  useEffect(() => {
    if (question.input.required) {
      context.assign({
        canSubmit: false,
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    setFormErrors({});
    context.assign({
      value: value,
    });

    debouncedValidate(value);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (value !== "") validate();
    }, 300),
    []
  );

  const validate = async () => {
    setFormErrors({ message: "Validating..." });
    let res = {};
    if (questionnaireVersion1.includes(getData("questionnaireID"))) {
      res = await hooks.api(question.input.validator, context);
    } else {
      res = await hooks.gql(question.input.validator, context);
    }

    if (res.errors) {
      context.assign({
        canSubmit: false,
      });
      setFormErrors(res.errors[0]);
    } else {
      context.assign({
        canSubmit: true,
      });
      setFormErrors({});
    }
  };

  return (
    <FormControl error fullWidth>
      <InputLabel htmlFor="text">{question.properties.locale.name}</InputLabel>
      <Input
        id="text"
        name="output"
        placeholder={question.properties.locale.placeholder}
        variant="filled"
        value={formValues.output}
        inputComponent={TextMaskCustom}
        onChange={handleInputChange}
        required={question.input.required}
        autoFocus
        inputProps={{
          maxLength: question.input.maxlength ? question.input.maxlength : 80,
          inputref: "ref-text",
          mask: question.input.mask || "***************",
        }}
        autoComplete="off"
        aria-describedby="text-helper-text"
      />
      <FormHelperText id="text-helper-text" style={{ fontSize: "0.9rem" }}>
        {formErrors.message}
      </FormHelperText>
    </FormControl>
  );
}

export default View;
